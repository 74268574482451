<template>
  <div
    :class="[
      'landing-download__flex__item',
      {
        'landing-download__flex__item--large': isLarge,
        'landing-download__flex__item--small': !isLarge,
      },
    ]"
  >
    <!-- TODO: handle images width (responsive too) -->
    <ImageLazy
      v-if="feature.image?.filename"
      :src="feature.image.filename"
      :alt="feature.image.alt"
      :title="feature.image.title"
      class-variant="landing-download__flex__img"
    />
    <h2 v-if="feature.title" class="h3 landing-download__flex__title">
      {{ feature.title }}
    </h2>
    <p v-if="feature.subtitle" class="landing-download__subtitle">
      <strong>{{ feature.subtitle }}</strong>
    </p>
    <ClientOnly>
      <p v-if="feature.text" class="landing-download__subtitle" v-html="richText" />
    </ClientOnly>
  </div>
</template>

<script>
export default {
  name: 'LdFeatureBlock',
  props: {
    isLarge: { type: Boolean, default: true },
    feature: { type: Object, required: true },
  },
  computed: {
    richText() {
      return this.feature.text ? renderRichText(this.feature.text).replace(/(<([/p^>]+)>)/gi, '') : ''
    },
  },
}
</script>

<style lang="scss">
.landing-download {
  &__flex {
    &__item {
      @include mqmin($mq-md) {
        flex: 1 0 0px;

        &:not(:last-child) {
          margin-right: 20px;
        }
      }

      &--large {
        @include mq-btw($mq-sm, $mq-md) {
          flex: 0 0 calc(50% - 20px / 2);
          margin-bottom: 40px;

          &:nth-child(odd) {
            margin-right: 20px;
          }
        }

        @include mq($mq-sm) {
          flex: 0 0 100%;
          margin-bottom: 40px;
        }
      }

      &--small {
        @include mq-btw($mq-sm, $mq-md) {
          flex: 0 0 calc(25% - 3 * 20px / 4);
          margin-bottom: 40px;
          margin-right: 20px;

          &:nth-child(4n) {
            margin-right: 0;
          }
        }

        @include mq($mq-sm) {
          flex: 0 0 calc(50% - 20px / 2);
          margin-bottom: 40px;

          &:nth-child(odd) {
            margin-right: 20px;
          }
        }
      }
    }

    &__img {
      display: block;
      margin-bottom: 20px;
      max-width: 100%;
    }

    &__title {
      color: var(--landing-color-secondary);
      margin-bottom: 20px;
    }
  }
}
</style>
