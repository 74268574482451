<template>
  <div class="inner-container fad">
    <h1 class="landing-download__title">
      {{ title }}
    </h1>
  </div>
</template>

<script>
export default {
  name: 'LdTitle',
  props: {
    title: { type: String, required: true },
  },
}
</script>

<style lang="scss" scoped>
.landing-download {
  &__title {
    margin-bottom: 10px;
    margin-top: 60px;
  }
}
</style>
