<template>
  <div class="inner-container fad">
    <p class="landing-download__subtitle u-c-landing-color-secondary">
      <strong>{{ subtitle }}</strong>
    </p>
  </div>
</template>

<script>
export default {
  name: 'LdSubtitle',
  props: {
    subtitle: { type: String, required: true },
  },
}
</script>

<style lang="scss">
.landing-download {
  &__subtitle {
    font-size: pxToRem(15px);
    line-height: 1.33;
    margin-bottom: 20px;

    @include mq($mq-md) {
      font-weight: 400;
    }

    p {
      // TODO: should not be needed anymore when newcss is removed
      font-size: pxToRem(15px);
      line-height: 1.33;
      margin-bottom: 20px;

      @include mq($mq-md) {
        font-weight: 400;
      }
    }

    strong,
    b {
      font-weight: 800;
    }

    &--no-mb {
      margin-bottom: 0;
    }
  }
}
</style>
