<template>
  <!-- TODO : this component could probably be merged with DownloadBanner component -->
  <div class="inner-container fad">
    <div class="demo-banner">
      <div v-if="image?.filename" class="demo-banner__img">
        <ImageLazy :src="image.filename" :alt="image.alt" :title="image.title" />
      </div>
      <div class="demo-banner__text">
        <div class="demo-banner__text-padding">
          <div class="demo-banner__bg" />
          <h2 class="h1 demo-banner__title">
            {{ title }}
          </h2>
          <p class="landing-download__subtitle">
            {{ text }}
          </p>
          <main-button
            :label="cta"
            link="https://calendly.com/stephane-stockpro/demostockpro"
            tag="a"
            target="_blank"
            icon-position="RIGHT"
            class="demo-banner__btn"
            class-variant="btn-blue full-width-sm"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MainButton from '@/components/Buttons/MainButton/MainButton'

export default {
  name: 'LdDemoBanner',
  components: {
    MainButton,
  },
  props: {
    image: { type: Object, required: true },
    cta: { type: String, required: true },
    title: { type: String, required: true },
    text: { type: String, required: true },
  },
}
</script>

<style lang="scss">
.demo-banner {
  margin: 60px 0;
  position: relative;

  @include mqmin($mq-sm) {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > * {
      flex: 1 1 0px;
      margin-left: 20px;
    }
  }

  @include mq($mq-sm) {
    border-radius: 20px;
    background-color: var(--landing-color-primary);
    padding: 30px;
  }

  @include mq($mq-xs) {
    padding: 20px;
  }

  &__text {
    border-radius: 20px;
    position: relative;
    flex-basis: calc(7 / 12 * 100%);
  }

  &__title {
    margin-bottom: 20px;
  }

  &__text-padding {
    padding: 30px;
    padding-left: 0;

    @include mq($mq-sm) {
      padding: 0;
    }

    & > * {
      position: relative;
    }
  }

  &__img {
    position: relative;
    z-index: 2;
    flex-basis: calc(5 / 12 * 100%);

    @include mq($mq-sm) {
      margin-bottom: 40px;
    }

    img {
      display: block;
      width: 100%;
      max-width: 385px;
      margin: 0 auto;
    }
  }

  &__bg {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: calc(100% / 7 * 12 + 40px);
    z-index: 0;
    border-radius: 20px;
    background-color: var(--landing-color-primary);

    @include mq($mq-sm) {
      display: none;
    }
  }
}
</style>
