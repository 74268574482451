<template>
  <div class="fullwith-banner" :style="backgroundColor">
    <div class="inner-container fad">
      <div class="fullwith-banner__flex" :class="{ rtl }">
        <div class="fullwith-banner__txt">
          <h2 class="fullwith-banner__title" :style="primaryColorText">
            {{ title }}
          </h2>
          <client-only>
            <div class="fullwith-banner__subtitle" :style="`--text-color:${primaryColor};`" v-html="richText" />
          </client-only>
          <main-button
            v-if="buttonText"
            :label="buttonText"
            :link="buttonUrl"
            tag="a"
            target="_blank"
            icon-position="RIGHT"
            class="fullwith-banner__btn"
            class-variant="full-width-md"
            :style-variant="[accentColorText, primaryColorBg]"
          >
            <ImageLazy
              v-if="buttonIcon && buttonIcon.filename"
              :src="`${buttonIcon.filename}`"
              :alt="`${buttonText}`"
              :title="`${buttonText}`"
              with="40px"
              height="40px"
            />
          </main-button>
        </div>
        <div v-if="image?.filename" class="fullwith-banner__img">
          <ImageLazy
            :src="image.filename"
            :alt="image.alt || $t('brandName')"
            :title="image.title || $t('brandName')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MainButton from '@/components/Buttons/MainButton/MainButton'

export default {
  name: 'LdFullwidthBanner',
  components: {
    MainButton,
  },
  props: {
    title: { type: String, default: '' },
    text: { type: [Object, String], default: '' },
    image: { type: Object, default: () => {} },
    rtl: { type: Boolean, default: false },
    buttonText: { type: String, default: undefined },
    buttonUrl: { type: String, default: '' },
    buttonIcon: { type: Object, default: undefined },
    bgColor: { type: String, default: '' },
    primaryColor: { type: String, default: '' },
    accentColor: { type: String, default: '' },
    // eslint-disable-next-line vue/prop-name-casing
    button_text: { type: String, default: '' },
    // eslint-disable-next-line vue/prop-name-casing
    button_url: { type: String, default: '' },
    // eslint-disable-next-line vue/prop-name-casing
    button_icon: { type: Object, default: () => {} },
    // eslint-disable-next-line vue/prop-name-casing
    bg_color: { type: String, default: '' },
    // eslint-disable-next-line vue/prop-name-casing
    primary_color: { type: String, default: '' },
    // eslint-disable-next-line vue/prop-name-casing
    accent_color: { type: String, default: '' },
  },
  computed: {
    richText() {
      if (this.text) {
        return renderRichText(this.text)
      }
      return null
    },
    backgroundColor() {
      return { 'background-color': this.bgColor }
    },
    primaryColorBg() {
      return { 'background-color': this.primaryColor }
    },
    primaryColorText() {
      return { color: this.primaryColor }
    },
    accentColorBg() {
      return { 'background-color': this.accentColor }
    },
    accentColorText() {
      return { color: this.accentColor }
    },
  },
}
</script>

<style lang="scss">
.fullwith-banner {
  margin: 60px 0;
  p {
    color: var(--text-color);
  }

  &__title,
  &__subtitle {
    margin-bottom: 20px;
  }

  &__flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 60px 0;

    @include mq($mq-sm) {
      flex-direction: column-reverse;
    }

    & > * {
      @include mqmin($mq-sm) {
        flex: 0 0 calc(50% - 20px / 2);
      }
    }
  }

  &__img {
    @include mq($mq-sm) {
      margin-bottom: 20px;
    }

    img {
      display: block;
      max-width: 100%;
    }
  }
  .rtl {
    flex-direction: row-reverse;
    @include mq($mq-sm) {
      flex-direction: column-reverse;
    }
  }
}
</style>
