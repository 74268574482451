<template>
  <div class="inner-container fad">
    <div class="landing-download__flex">
      <LdFeatureBlock
        v-for="feature in features"
        :key="feature._uid"
        :feature="feature"
        :is-large="features.length <= 3"
      />
    </div>
  </div>
</template>

<script>
import LdFeatureBlock from '@/components/LandingDownload/LdFeatureBlock'

export default {
  name: 'LdFeaturesSection',
  components: {
    LdFeatureBlock,
  },
  props: {
    features: { type: Array, required: true },
  },
}
</script>

<style lang="scss">
.landing-download {
  &__flex {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    margin-bottom: 60px;

    @include mq($mq-md) {
      flex-wrap: wrap;
      margin-bottom: 0;
      justify-content: flex-start;
    }
  }
}
</style>
